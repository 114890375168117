html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}
